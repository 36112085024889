import React from "react"
import styled, { css } from "styled-components"
import { Modal as M } from "react-bootstrap"

import { colors } from "../../utils/colors"
import { useIcon } from "../../utils/functions"

const Card = styled.img`
    width: 100%;
    display: ${props => (props.show ? "flex" : "none")};
    cursor: pointer;

    @media (min-width: 992px) {
        ${props =>
            props.height &&
            css`
                height: ${props.height};
            `}
    }
`

const DotColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px auto;

    @media (min-width: 992px) {
        margin: 16px auto;
    }
`

const DotButton = styled.button`
    border: none;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 0 16px 0 0;

    color: ${colors.white};

    background: ${props =>
        props.active ? colors.white : colors.transparentWhite};

    @media (min-width: 992px) {
        margin: 0 24px 0 0;
    }
`

const Modal = styled(M)`

    .modal-content{
        justify-content: center;
    }

    button {
        display: flex;
        align-items: center;
        font-size: 28px;
        padding: 10px;
        border: none;
        color: ${colors.transparentBlack};
        background: ${colors.transparentWhite2};

        position: absolute;

        padding: 16px;

        &.prev {
            left: -40px;
        }

        &.next {
            right: -40px;
        }
    }
`

const MobileImg = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    button {
        display: flex;
        align-items: center;
        font-size: 21px;
        padding: 8px;
        border: none;
        color: ${colors.transparentBlack};
        background: ${colors.transparentWhite2};
        position: absolute;

        &.prev {
            left: 24px;
        }

        &.next {
            right: 24px;
        }

        @media (min-width: 576px) {
            display: none;
        }
    }
`

const Lightbox = ({ data, height, size }) => {
    const [element, setElement] = React.useState(0)
    const [show, setShow] = React.useState(false)

    const prev = i => {
        if (i >= 0 && i <= data.length - 1) {
            setElement(element - 1)
        }

        if (i === 0) {
            setElement(data.length - 1)
        }
    }

    const next = i => {
        if (i !== data.length - 1) {
            return setElement(element + 1)
        }

        setElement(0)
    }

    return (
        <>
            <MobileImg className="d-flex d-sm-none">
                <button className="prev" onClick={() => prev(element)}>
                    {useIcon("prev")}
                </button>

                <img
                    className="img-fluid"
                    src={data[element]}
                    alt="GD8 Incorporadora - Projetos e Empreendimentos"
                    title="GD8 Incorporadora - Projetos e Empreendimentos"
                />

                <button className="next" onClick={() => next(element)}>
                    {useIcon("next")}
                </button>
            </MobileImg>

            <div className="d-none d-sm-flex flex-column">
                {data.map((item, index) => (
                    <Card
                        height={height}
                        key={index}
                        src={data[element]}
                        show={index === 0}
                        onClick={() => {
                            setShow(true)
                            setElement(index)
                        }}
                    />
                ))}

                <DotColumn className="col">
                    {data.map((item, index) => (
                        <DotButton
                            key={index}
                            type="button"
                            active={index === element}
                            onClick={() => setElement(index)}
                        />
                    ))}
                </DotColumn>

                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    centered
                    size={size}
                >
                    <button className="prev" onClick={() => prev(element)}>
                        {useIcon("prev")}
                    </button>

                    <img
                        className="img-fluid"
                        src={data[element]}
                        alt="GD8 Incorporadora - Projetos e Empreendimentos"
                        title="GD8 Incorporadora - Projetos e Empreendimentos"
                    />

                    <button className="next" onClick={() => next(element)}>
                        {useIcon("next")}
                    </button>
                </Modal>
            </div>
        </>
    )
}

export default Lightbox
